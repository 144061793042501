import React from 'react'
import { navigateTo } from 'gatsby-link'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

export default class Contact extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const form = e.target
    try {
      await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
      await navigateTo(form.getAttribute('action'))
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" keywords={[`Contact Form`]} />

        <div className="leading-normal">
          <form
            name="contact"
            method="post"
            action="/thanks?no-cache=1"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p className="hidden">
              <label>Don’t fill this out</label>
              <input name="bot-field" onChange={this.handleChange} />
            </p>

            <label className="block font-bold mb-2 text-xs uppercase">Name</label>
            <input
              className="appearance-none block bg-clouds mb-6 p-3 rounded-md text-grey-darker w-full"
              name="name"
              onChange={this.handleChange}
              placeholder="Your First and Last Name"
              required
              size="45"
              style={{ boxShadow: 'none' }}
              type="text"
            />
            <label className="block font-bold mb-2 text-xs uppercase">Email</label>
            <input
              className="appearance-none block bg-clouds mb-6 p-3 rounded-md text-grey-darker w-full"
              name="email"
              onChange={this.handleChange}
              placeholder="Your Email Address"
              required
              size="45"
              style={{ boxShadow: 'none' }}
              type="email"
            />
            <label className="block font-bold mb-2 text-xs uppercase">Message</label>
            <textarea
              className="appearance-none bg-clouds mb-6 p-3 rounded-md text-grey-darker w-full"
              name="message"
              onChange={this.handleChange}
              placeholder="Your message"
              required
              rows="8"
              columns="45"
              style={{ boxShadow: 'none' }}
            />
            <button
              className="bg-nephritis hover:bg-emerald font-normal px-6 py-3 rounded text-sm text-white"
              type="submit"
            >
              Send
            </button>
          </form>
        </div>
      </Layout>
    )
  }
}
